<template>
    <div>
        <div v-if="view === 'login'">
            <panel v-if="showVerified === true">
                Your email address was verified!
            </panel>
            <panel v-if="showVerified === false">
                Your email validation link was not valid, or it expired.
            </panel>
            <panel header="Account Login" header-type="h1" v-show="show">
                <error-details ref="errorDetails"></error-details>
                <text-input id="email-input" label="Email" placeholder="johndoe@test.com" v-model="username" name="email"></text-input>
                <password-input id="password-input" v-model="password"></password-input>
                <div class="form-group">
                    <action-button id="submit-login" text="Login" type="primary,block" @click="login()"></action-button>
                    <div class="help-block container-flex justify-content-between align-items-center">
                        <checkbox-input v-model="rememberMe" value="remember_me" label="Remember me"></checkbox-input>
                        <a @click="openForgotPassword()">Forgot Password</a>
                    </div>
                </div>
            </panel>
            <forgot-password @closedForgotPassword="open"></forgot-password>
            <change-password @closedChangePassword="open"></change-password>
        </div>
        <div v-if="view === '2fa'">
            <error-details ref="errorDetails"></error-details>
            <panel header="Account Login" header-type="h1" v-if="available_2fa_methods && selected_2fa_method === null">
                <p>Select an authentication method</p>
                <!-- todo, show the masked email address, so they know which one they are picking if they have multiple -->
                <div v-for="method in available_2fa_methods" v-on:click="setSelectedMethod(method)" class="twoFactorOptions">
                    <div class="twoFactorOptionsName">{{getNameForType(method.type)}}</div>
                    <div class="twoFactorOptionsDesc">{{method.nickname}}</div>
                </div>
            </panel>

            <panel header="Account Login" header-type="h1" v-if="selected_2fa_method">
                <div v-if="selected_2fa_method.type === 'TOTP'">
                    <OtpInput
                        v-model="provided_code"
                        ref="provided_code"
                        label="Enter the code from your authentication app:"
                        @keydown.enter="loginWithTotp()"
                    />
                    <action-button id="submit-login-totp" text="Submit" type="primary,block" @click="loginWithTotp()"></action-button>
                </div>
                <div v-if="selected_2fa_method.type === 'Email'">
                    <p>A One Time Password has been e-mailed to you. Enter the it below.</p>
                    <OtpInput
                        v-model="provided_code"
                        ref="provided_code"
                        label="One Time Password from your e-mail:"
                        @keydown.enter="loginWithTotp()"
                    />
                    <action-button id="submit-login-totp" text="Submit" type="primary,block" @click="loginWithTotp()"></action-button>
                </div>
                <div v-if="selected_2fa_method.type === 'Sms'">
                    <p>A One Time Password has been sent via SMS.</p>
                    <OtpInput
                        v-model="provided_code"
                        ref="provided_code"
                        label="One Time Password from your text message:"
                        @keydown.enter="loginWithTotp()"
                    />
                    <action-button id="submit-login-totp" text="Submit" type="primary,block" @click="loginWithTotp()"></action-button>
                </div>
            </panel>
        </div>
    </div>
</template>

<script>

import axios from "src/Vue/common/utilities/http";
import Panel from "src/Vue/common/components/Panel";
import ErrorDetails from "src/Vue/common/components/AlertDetails";
import TextInput from "src/Vue/common/components/TextInput";
import PasswordInput from "src/Vue/common/components/PasswordInput";
import ActionButton from "src/Vue/common/components/ActionButton";
import CheckboxInput from "src/Vue/common/components/CheckboxInput";
import ForgotPassword from "src/Vue/login/components/ForgotPassword";
import ChangePassword from "src/Vue/login/components/ChangePassword";
import OtpInput from "@/Vue/common/components/OtpInput.vue";

export default {
    name: 'Login',
    components: {ChangePassword, ForgotPassword, CheckboxInput, ActionButton, PasswordInput, TextInput, ErrorDetails, Panel, OtpInput},
    props: {
        onAuthSuccess: {
            type: Function,
            required: false,
            default: function (loginResponse) {
                window.location = loginResponse?.redirectTo ?? '/terminal';
            }
        }
    },
    data() {
        return {
            username: null,
            password: null,
            companyId: window.companyId,
            errorMessage: null,
            rememberMe: false,
            show: true,
            showVerified: null,
            view: 'login',
            available_2fa_methods: [],
            selected_2fa_method: null,
            provided_code: null,
        }
    },
    mounted() {
        let stored = localStorage.getItem('remember_me');
        if (stored) {
            this.username = stored;
            this.rememberMe = true;
        }
        window.eventHub.$on('hideLoginView', this.close);
        window.eventHub.$on('showLoginView', this.open);

        const urlParams = new URLSearchParams(window.location.search);
        let queryParamShowVerify = urlParams.get('verified');
        if (queryParamShowVerify === 'true') {
            this.showVerified = true;
        } else if (queryParamShowVerify === 'false') {
            this.showVerified = false;
        }
    },
    methods: {
        login() {
            this.$refs.errorDetails.reset();
            if (!this.username) {
                this.$refs.errorDetails.addDetail('Invalid email');
            }
            if (!this.password) {
                this.$refs.errorDetails.addDetail('Invalid password');
            }
            if (!this.username || !this.password) {
                this.$refs.errorDetails.setErrorMessage('Please enter valid login credentials');
                return;
            }

            let params = {
                email: this.username,
                password: this.password,
            };

            if (this.rememberMe) {
                localStorage.setItem('remember_me', this.username);
            } else {
                localStorage.removeItem('remember_me');
            }
            axios.post('/api/login', params).then((response) => {
                if (response.data && response.data.available_2fa_methods) {
                    // They have 2fa, select a method (or assume it if they only have one)
                    this.view = '2fa';
                    this.available_2fa_methods = response.data.available_2fa_methods;
                    if (this.available_2fa_methods.length === 1 && typeof this.selected_2fa_method === 'method') {
                        this.selected_2fa_method(this.available_2fa_methods[0]);
                    }
                }
                if (response.data.error) {
                    this.$refs.errorDetails.setErrorMessage(response.data.msg);
                } else if (response.data.user_id > 0) {
                    this.onAuthSuccess(response.data)
                }
            }, (error) => {
                this.$refs.errorDetails.setErrorMessage(error.response.data.msg);
            });
        },
        getNameForType(type) {
            if (type === 'Sms') {
                return 'SMS';
            } else if (type === 'Email') {
                return 'E-mail';
            } else if (type === 'TOTP') {
                return 'Authentication App';
            } else if (type === 'WebAuthN') {
                return 'Hardware Key';
            }
            return type;
        },
        setSelectedMethod(method) {
            this.selected_2fa_method = method;
            this.provided_code = '';
            this.requestOneTimePassword();
        },
        requestOneTimePassword() {
            if (this.selected_2fa_method === null) {
                return;
            }
            console.log(this.selected_2fa_method);
            let params = {
                method: this.selected_2fa_method
            };

            axios.put('/api/login2fa', params).then((response) => {
                console.log(response.data);
                if (response.data.error) {
                    this.$refs.errorDetails.setErrorMessage(response.data.msg);
                } else if (response.data.user_id > 0) {
                    this.onAuthSuccess(response.data)
                }
                if (response.data.msg === 'Too many 2FA attempts.') {
                    this.view = 'login';
                    this.setSelectedMethod(null);
                }

            }, (error) => {
                this.$refs.errorDetails.setErrorMessage(error.response.data.msg);
            });
        },
        loginWithTotp() {
            // We may rename this and use it for all methods
            this.$refs.errorDetails.reset();
            if (!this.username) {
                this.$refs.errorDetails.addDetail('Invalid email');
            }
            if (!this.password) {
                this.$refs.errorDetails.addDetail('Invalid password');
            }
            if (!this.username || !this.password) {
                this.$refs.errorDetails.setErrorMessage('Please enter valid login credentials');
                return;
            }

            let params = {
                data: {"code": this.provided_code},
                method: this.selected_2fa_method
            };

            axios.post('/api/login2fa', params).then((response) => {
                console.log(response.data);
                if (response.data.error) {
                    this.$refs.errorDetails.setErrorMessage(response.data.msg);
                } else if (response.data.user_id > 0) {
                    this.onAuthSuccess(response.data)
                }
                if (response.data.msg === 'Too many 2FA attempts.') {
                    this.view = 'login';
                    this.setSelectedMethod(null);
                }

            }, (error) => {
                this.$refs.errorDetails.setErrorMessage(error.response.data.msg);
            });
        },
        open() {
            this.show = true;
        },
        close() {
            this.show = false;
        },
        openForgotPassword() {
            window.eventHub.$emit('showForgotPassword');
            this.close();
        },
    }
}
</script>

<style>
    .twoFactorOptions {
        padding:20px;
        margin: 10px 0px 10px 0px;
        border: 1px solid #808080;
        border-radius: 10px;
        cursor: pointer;
    }
    .otp-input {
        margin: 10px 0px 10px 0px;
    }
    .twoFactorOptionsName {
        font-weight: bold;
    }
    .twoFactorOptionsDesc {
        margin-left:15px;
    }
</style>
