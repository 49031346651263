<template>
    <label-group :label="label"></label-group>
    <div id="otp-container" class="form-control p-0 border-0">
        <input
            class="otp-input"
            v-for="otp in codeSize"
            :key="otp"
            :value="value ? value[otp - 1] : ''"
            placeholder="0"
            type="numeric"
            maxlength="1"
            @keydown="(event) => handleKeyDown(event, otp)"
        />
    </div>
</template>

<script setup>
import LabelGroup from "@/Vue/common/components/LabelGroup.vue";
import {computed, onMounted} from "vue";

const props = defineProps({
    value: { type: String, default: '' },
    codeSize: { type: Number, default: 6 },
    label: { type: String, default: '' }
});

const emit = defineEmits(['input']);
const controlKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab', 'Shift', 'Meta'];
const inputs = computed(() => {
    return document.getElementsByClassName('otp-input');
});

const updateCodeAtGivenPosition = (stringToAdd, index) => {
    // Create an empty string with code size length
    let startValue = Array(props.codeSize).fill(' ').join('');
    let originalString = props.value ?? startValue;
    let newValue = '';

    // Put the string at the start
    if (!originalString && index === 0) {
        newValue = stringToAdd;
    } else {
        // Update at a given position
        originalString = originalString.split('');
        originalString.splice(index, 1, stringToAdd);
        newValue = originalString.join('');
    }

    emit('input', newValue);
}

const handleKeyDown = (e, otp) => {
    let index = otp - 1;
    let isNumeric = /^[0-9]{1}$/.test(e.key);
    handleControlKeys(e, index);
    if (!isNumeric) {
        e.preventDefault();
    }

    if (isNumeric) {
        updateCodeAtGivenPosition(e.key, index);
        moveToInput(index + 1);
    }
};

const handleControlKeys = (e, index) => {
    let newFocusedInput = index;

    switch (e.key) {
        case 'Backspace':
            newFocusedInput = index > 0 ? index - 1 : index;
            updateCodeAtGivenPosition(' ', index);
            break;
        case 'ArrowLeft':
            newFocusedInput = index > 0 ? index - 1 : index;
            break;
        case 'Tab':
        case 'ArrowRight':
            newFocusedInput = index < (props.codeSize - 1) ? index + 1 : index;
            break;
        case 'Enter':
            emit('keydown', e);
            return;
        case 'Delete':
            updateCodeAtGivenPosition(' ', index);
        default:
            break;
    }

    // Check if the user pressed Ctrl + V
    if ( (e.ctrlKey || e.metaKey ) && e.key === 'v' ) {
        handlePaste(e);
        return;
    }

    if (controlKeys.includes(e.key)) {
        moveToInput(newFocusedInput);
    }

}

const moveToInput = (nextIndex) => {
    setTimeout(() => {
        let nextInput = inputs.value[nextIndex];
        if (nextInput) {
            nextInput.focus();
        }
    }, 10)
}

const handlePaste = async (e) => {
    try {
        e.preventDefault();
        const pastedText = e.clipboardData ? e.clipboardData.getData('text') : await navigator.clipboard.readText();
        if (!/^[0-9]/.test(pastedText)) {
            return;
        }
        const digits = pastedText.split('');
        for (let index in inputs.value) {
            let input = inputs.value[index];
            if (typeof input === 'object') {
                input.value = digits[index] ?? '';
            }
        }

        inputs.value[inputs.value.length - 1].focus();
        emit('input', pastedText);
    } catch (error) {
        console.error(error);
    };
};

onMounted(() => {
    for(let input of inputs.value) {

        if (typeof input === 'object') {
            input.addEventListener('paste', handlePaste);
        }
    }
    moveToInput(0);
})

</script>
<style scoped>
.otp-input {
    width: 35px;
    height: 45px;
    text-align: center;
    border-left: solid 1px #ced4da;
    border-top: solid 1px #ced4da;
    border-bottom: solid 1px #ced4da;
    border-right: solid 1px transparent;
}

.otp-input:hover,
.otp-input:focus,
.otp-input:active {
    border-color: #66afe9;
    outline: 0;
    webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
    border-right: solid 1px #66afe9;
}

.otp-input:first-child {
    border-radius: 0.375rem 0rem 0rem 0.375rem;
}

.otp-input:last-child {
    border-radius: 0rem 0.375rem 0.375rem 0rem;
    border-right: solid 1px #ced4da!important;
}
</style>
